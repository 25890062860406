



































































































































































































































































































.main_categories_wrapper {
  .wrap_every_category {
    // background: #eee;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    padding: 5px 0;
    @media (min-width: 320px) and (max-width: 480px) {
      display: block;
    }
    .part_a {
      white-space: normal;
      word-break: break-word;
    }
    .part_b {
      display: flex;
      gap: 10px;
      @media (min-width: 320px) and (max-width: 480px) {
        margin-top: 5px;
      }
      .actions-icons {
        font-size: 14px;
        width: 30px;
        height: 30px;
        box-shadow: 0 0 6px 0 rgba(#000, 0.2), 0 0 5px -2px rgba(#000, 0.1);
        text-align: center;
        line-height: 30px;
        border-radius: 50%;
        cursor: pointer;
        color: #777;
        transition: 0.3s;

        &.add {
          &:hover {
            background-color: #11bfe3;
            color: #fff;
          }
        }

        &.edit {
          &:hover {
            background-color: #ffa534;
            color: #fff;
          }
        }

        &.delete {
          &:hover {
            background-color: #fb404b;
            color: #fff;
          }
        }
      }
    }
  }
}

::v-deep .el-dialog {
  @media (min-width: 320px) and (max-width: 480px) {
    width: 90% !important;
  }
  @media (min-width: 480px) and (max-width: 767px) {
    width: 70% !important;
  }
}
.dialog_footer {
  display: flex;
  justify-content: end;
  align-items: center;
  @media (min-width: 320px) and (max-width: 991px) {
    justify-content: center;
  }
}
